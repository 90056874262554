import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetTime }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isTimeUp, setIsTimeUp] = useState(false);

 
  useEffect(() => {
    const targetDate = new Date(targetTime);
    let timer; // Declare 'timer' outside the function

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setIsTimeUp(true);
        setTimeRemaining('Time is up!');
        clearInterval(timer); // Stop the timer
        return;
      }

      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeRemaining(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    };

    updateCountdown();
    timer = setInterval(updateCountdown, 1000); // Initialize 'timer'

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [targetTime]);

  const timeInMilliseconds = new Date(targetTime) - new Date();
  const isLessThanNineMinutes = timeInMilliseconds <= 9 * 60 * 1000;

 

  return (
    <div
      style={{
        fontSize: '10px',
        color: isLessThanNineMinutes ? 'red' : 'black',
      }}
    >
      {timeRemaining}
    </div>
  );
};

export default CountdownTimer;
