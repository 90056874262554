import React, { useState, useEffect } from 'react';

const CountdownApiTimer = ({ targetTime, verifyApi }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    const targetDate = new Date(targetTime);
    let timer;

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setIsTimeUp(true);
        setTimeRemaining('Time is up!');
        clearInterval(timer); // Stop the countdown timer
        return;
      }

      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeRemaining(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    };

    updateCountdown();
    timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [targetTime]);

  // Verify API call every 3 seconds until the time elapses
  useEffect(() => {
    let verifyInterval;

    const callVerifyApi = async () => {
      try {
        const response = await verifyApi(); // Call the verify API
        console.log('Verification Response:', response); // Handle response
      } catch (error) {
        console.error('API Call Failed:', error); // Handle error
      }
    };

    if (!isTimeUp) {
      callVerifyApi(); // Initial call
      verifyInterval = setInterval(callVerifyApi, 3000); // Call every 3 seconds
    }

    return () => clearInterval(verifyInterval); // Cleanup on unmount or time up
  }, [isTimeUp, verifyApi]);

  const timeInMilliseconds = new Date(targetTime) - new Date();
  const isLessThanNineMinutes = timeInMilliseconds <= 9 * 60 * 1000;

  return (
    <div
      style={{
        fontSize: '16px',
        color: isLessThanNineMinutes ? 'red' : 'white',
      }}
    >
      {timeRemaining}
    </div>
  );
};

export default CountdownApiTimer;
