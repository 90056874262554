import React from 'react'
import { ClipLoader, PulseLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div className='h-screen w-full z-40 bg-[#96969646] flex flex-col justify-center items-center absolute top-0 left-0'>


<PulseLoader color={"white"} size={15} />
    </div>
  )
}

export default Loader