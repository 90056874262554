import { ArrowRight, Building } from "iconsax-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../assets/success.json";
import moment from "moment";

const SuccessPage = () => {
  const location = useLocation();
  const payData = location.state?.payData;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="min-h-screen w-full z-10 bg-gray-50 p-4 flex justify-center">
         <img
        src="/image/vector.png"
        alt="bg"
        className="absolute top-0 left-0 -z-10 h-full w-full object-cover "
      />
      <div className="max-w-md w-full bg-white mt-8 jost mx-auto rounded-xl shadow-lg px-4 py-8  md:px-8 p-8">
        {/* Success Icon */}
        <div className="flex justify-center">
          <div className=" rounded-full p-3">
            <Lottie options={defaultOptions} height={90} width={90} />
          </div>
        </div>

        {/* Success Message */}
        <div className="text-center mt-5">
          <h1 className=" textlg md:text-2xl font-bold text-gray-800">
            Payment Successful!
          </h1>
          <p className="text-gray-600 mt-2 text-[14px] md:text-base">
            Your payment has been processed successfully. A confirmation email
            has been sent to your inbox.
          </p>
        </div>

        {/* Payment Details */}
        <div className="mt-14 bg-[#a8faca3d] rounded-lg text-[14px] md:text-[16px] p-2 md:p-4">
          <div className="flex justify-between items-center mb-4">
            <span className="text-gray-600">Amount Paid</span>
            <span className="text-gray-800 font-medium">
              {payData?.data?.amount}
            </span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="text-gray-600">Transaction Ref</span>
            <span className="text-gray-800 font-medium">
              {payData?.data?.reference}
            </span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="text-gray-600">Date</span>
            <span className="text-gray-800 font-medium">
           {moment(payData?.data?.date).format("MMM DD, HH:mm:ss")}
            </span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-14 space-y-4">
          <Link
            to="/"
            className="w-full bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 font-medium py-2 px-4 rounded-lg flex items-center justify-center gap-2 transition-colors"
          >
            <Building className="w-5 h-5" />
            What Is Vant ?
          </Link>
        </div>

        {/* Help Link */}
        <div className="mt-6 text-center">
          <a
            href="mailto: support@vantapp.com"
            className="text-[#26ae5f] hover:text-[#1be46f] inline-flex items-center gap-1 text-sm"
          >
            Need help? Contact support
            <ArrowRight className="w-4 h-4" />
          </a>
        </div>
        <Link to="https://services.ndpc.gov.ng/portal/?page=verify-c&d=dn36294120662&id=24054&sn=40fc0a2e43986e15e06547213854b27b&t=dp_registration&tp=nwp_eosic" className="w-full"><img src="./image/ndpr.PNG" alt="ndpr" className="mx-auto h-[30px]" /></Link>
      </div>
    </div>
  );
};

export default SuccessPage;
